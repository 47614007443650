<template>
    <MenuItem v-bind="$attrs">
        <span
            class="flex gap-4 items-center cursor-pointer px-4 py-2 text-sm"
            :class="textClasses">
            <slot name="icon">
                <Icon
                    v-if="localIcon"
                    :name="localIcon"
                    class="w-5 h-5"
                    :class="iconClass" />
            </slot>
            <slot />
        </span>
    </MenuItem>
</template>

<script setup>
const props = defineProps({
    icon: {
        type: String,
        default: null
    },
    iconClass: {
        type: String,
        default: null
    },
    deleteItem: {
        type: Boolean,
        default: false,
    }
})

const localIcon = computed(() => {
    if (props.deleteItem) {
        return 'heroicons:trash'
    } else {
        return props.icon
    }
})

const textClasses = computed(() => {
    if (props.deleteItem) {
        return 'text-danger-600 dark:text-danger-400 hover:bg-danger-100 dark:hover:bg-danger-600'
    } else {
        return 'text-gray-700 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-600'
    }
})
</script>